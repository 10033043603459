import React from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "hooks/useAppDispatch";
import { selectCategory5 } from "redux/features/declarations/declarationsSlice";
import {
  Button,
  ButtonGroup,
  Card,
  Inline,
  RadioButton,
  Text,
  DataTable,
} from "@shopify/polaris";
import SaveBar from "components/SaveBar/SaveBar";
import { t } from "i18next";
import formatRow from "utils/formatRow";

const SubcategoryStep5 = ({ toNextStep, toPrevStep }) => {
  const dispatch = useAppDispatch();
  const {
    eprDeclaration: { subcategories_data },
    eprSubcategories: { selectedCategory5, selectedCategory4 },
  } = useSelector((state) => state.declarations);

  const handleChange = (
    checked,
    category,
    subCategory,
    subSubCategory,
    subSubSubCategory,
    subSubSubSubCategory
  ) => {
    dispatch(
      selectCategory5({
        checked,
        category,
        subCategory,
        subSubCategory,
        subSubSubCategory,
        subSubSubSubCategory,
      })
    );
  };

  const columns = [
    {
      header: {
        label: "Subcategory 1",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[0];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 2",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[1];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 3",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[2];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 4",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[3];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 5",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            const [category, subCategory, subSubCategory, subSubSubCategory] =
              rowData;

            const checkboxes =
              subcategories_data.subcategory_5[subSubSubCategory];

            return (
              <Inline wrap spacing='4'>
                {checkboxes.map((checkbox) => {
                  const id =
                    checkbox +
                    subCategory +
                    subSubCategory +
                    subSubSubCategory +
                    category;

                  return (
                    <RadioButton
                      key={id}
                      id={id}
                      value={checkbox}
                      label={checkbox}
                      checked={selectedCategory5.some(
                        (c) =>
                          c[0] === category &&
                          c[1] === subCategory &&
                          c[2] === subSubCategory &&
                          c[3] === subSubSubCategory &&
                          c[4] === checkbox
                      )}
                      onChange={(checked) =>
                        handleChange(
                          checked,
                          category,
                          subCategory,
                          subSubCategory,
                          subSubSubCategory,
                          checkbox
                        )
                      }
                    />
                  );
                })}
              </Inline>
            );
          },
        ],
      },
    },
  ];

  const rows = formatRow(selectedCategory4, columns);

  return (
    <>
      <Card sectioned>
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text"]}
          headings={columns.map(({ header }) => (
            <Text fontWeight='semibold'>{header.label}</Text>
          ))}
          rows={rows}
        />
      </Card>

      <SaveBar>
        <ButtonGroup>
          <Button onClick={toPrevStep}>{t("eprReporting.back")}</Button>
          <Button primary onClick={toNextStep}>
            {t("orderReg.next")}
          </Button>
        </ButtonGroup>
      </SaveBar>
    </>
  );
};

export default SubcategoryStep5;
