import React from "react";
import { useSelector } from "react-redux";
import { Card, Text, DataTable } from "@shopify/polaris";
import formatRow from "utils/formatRow";

const Summary = ({ stepBar }) => {
  const {
    eprSubcategories: { selectedCategory5 },
  } = useSelector((state) => state.declarations);

  const columns = [
    {
      header: {
        label: "Subcategory 1",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[0];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 2",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[1];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 3",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[2];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 4",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[3];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 5",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[4];
          },
        ],
      },
    },
    {
      header: {
        label: "Quantity",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[5];
          },
        ],
      },
    },
  ];

  const rows = formatRow(selectedCategory5, columns);

  return (
    <>
      <Card sectioned>
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text", "text"]}
          headings={columns.map(({ header }) => (
            <Text fontWeight='semibold'>{header.label}</Text>
          ))}
          rows={rows}
        />
      </Card>

      {stepBar()}
    </>
  );
};

export default Summary;
