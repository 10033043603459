import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import Modal from "components/Modal/Modal";
import { isTaxAgentSelector } from "redux/selectors";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import PageHelmet from "components/PageHelmet";
import UserGuide from "components/UserGuide/UserGuide";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import withRouter from "helpers/withRouter";
import { formatDate, formatTime } from "utils/dates";
import {
  fetchLastUploadedTansation,
  fetchNews,
  fetchDeemedVat,
  fetchBalance,
  editBalance,
  fetchTransByCountryOmp,
  fetchCredits,
} from "redux/features/dashboard/dashboardSlice";
import { fetchDueDates } from "redux/features/dueDates/dueDatesSlice";
import {
  fetchThresholdEU,
  fetchThresholdUS,
} from "redux/features/thresholds/thresholdsSlice";
import { fetchPaymentInfo } from "redux/features/profile/profileSlice";
import {
  fetchDeclarations,
  fetchDeclarationsSalesTax,
  fetchEprDeclarations
} from "redux/features/declarations/declarationsSlice";
import { fetchCompanyDefaultLanguage } from "redux/features/user/userSlice";
import { fetchBonusProgram } from "redux/features/tax/taxSlice";
import { getCurrencySymbol } from "utils/currency";
import { formatMoney } from "utils/numbers";
import {
  Page,
  Layout,
  Card,
  TextStyle,
  Stack,
  Button,
  Badge,
  TextContainer,
  Spinner,
  Scrollable,
  ResourceList,
  ResourceItem,
  TextField,
  ButtonGroup,
  Banner,
  Icon,
  DataTable,
  Heading,
  Pagination,
  Text,
  Inline,
  AlphaStack,
} from "@shopify/polaris";
import { EditMajor } from "@shopify/polaris-icons";
import HighlightableDataTable from "components/HighlightableDataTable";
import formatRow from "utils/formatRow";

import { AddIcon, AlertIcon } from "icons";
import CardImg from "img/card.svg";
import { ReactComponent as CalendarImg } from "img/calendar-img.svg";
import { ReactComponent as ReminderImg } from "img/reminder-img.svg";
import { ReactComponent as FinanceImg } from "img/finance-img.svg";

import "./style.scss";
import { createCustomDispatch } from "helpers/customDispatch";

const defaultLimitDecl = 3;
const defaulLimitDueDates = 4;
const defaultLimitNews = 4;
const defaulLimitThresholdUS = 10;
const defaultLimitTransByCountryOmp = 10;
const chartSeriesColors = ["#9c6ade", "#59c7c5", "#0090f0", "#5C6AC4"];

ChartJS.register(...registerables);

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStateInd: 0,
      actionDialogs: {
        subscription: { open: false },
        contactSupport: { open: false },
      },
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user.is_tax_agent || (user.is_manager && user.company.is_tax_agent)) {
      this.props.fetchBonusProgram();
    } else {
      this.props.fetchDueDates({ limit: defaulLimitDueDates });
    }

    if (user.is_omp) {
      this.props.fetchDeemedVat();
      this.props.fetchTransByCountryOmp({
        limit: defaultLimitTransByCountryOmp,
      });
    }

    this.props.fetchLastUploadedTansation();
    this.props.fetchNews({ limit: defaultLimitNews });
    this.props.fetchDeclarations({ limit: defaultLimitDecl });
    this.props.fetchDeclarationsSalesTax({ limit: defaultLimitDecl });
    this.props.fetchEprDeclarations({ limit: defaultLimitDecl });
    this.props.fetchThresholdEU({ year: new Date().getFullYear() });
    this.props.fetchCredits();
    this.props.fetchThresholdUS({
      year: new Date().getFullYear(),
      limit: defaulLimitThresholdUS,
    });
    this.props.fetchPaymentInfo();

    this.props
      .fetchBalance()
      .then(() => this.setState({ balance: this.props.balance.amount || 0 }))
      .catch(_.noop);
  }

  getPeriodTypeForVat = (value) => {
    const { t } = this.props;
    switch (value) {
      case "year":
        return t("dash.yVAT");
      case "quarter":
        return t("dash.qVAT");
      case "month":
        return t("dash.mVAT");
      case "bi-annual":
        return "Bi-Annual VAT";
      default:
        return value;
    }
  };

  getPeriodTypeForEPR = (value) => {
    const { t } = this.props;
    switch (value) {
      case "year":
        return t("dash.yEPR");
      case "quarter":
        return t("dash.qEPR");
      case "month":
        return t("dash.mEPR");
      default:
        return value;
    }
  };

  getPeriodTypeForSalesTax = (value) => {
    const { t } = this.props;
    switch (value) {
      case "year":
        return t("dash.ySalexTax");
      case "quarter":
        return t("dash.qSalesTax");
      case "month":
        return t("dash.mSalesTax");
      case "bi-annual":
        return "Bi-Annual Sales tax";
      default:
        return value;
    }
  };

  getStatusForVat = (value) => {
    const { t } = this.props;
    switch (value) {
      case "submitted":
        return <Badge status={"success"}>{t("dash.statuses.submitted")}</Badge>;
      case "auto-submitted":
        return <Badge status={"success"}>{t("dash.statuses.autoSub")}</Badge>;
      case "new":
        return <Badge status={"info"}>{t("dash.statuses.new")}</Badge>;
      case "declined":
        return <Badge status={"warning"}>{t("dash.statuses.declined")}</Badge>;
      case "draft":
        return <Badge>{t("dash.statuses.draft")}</Badge>;
      case "pending":
        return <Badge status={"attention"}>{t("dash.statuses.pending")}</Badge>;
      default:
        return <Badge status={"attention"}>{value}</Badge>;
    }
  };

  doEditBalance = () => {
    this.props
      .editBalance({ amount: this.state.balance })
      .then(() => {
        this.props.fetchBalance();
        this.setState({
          editing: false,
          editingError: false,
        });
      })
      .catch(() => {
        this.setState({ editingError: "Error" });
      });
  };

  createMatrix = (m, n) => {
    const result = [];
    for (let i = 0; i < n; i++) {
      result.push(new Array(m).fill(null));
    }
    return result;
  };

  handleActionDialogsOpen(name, data = {}) {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  }

  handleActionDialogsClose(name) {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  }

  beforeDraw = function (
    chart,
    percentFact,
    thresholdFactSum,
    thresholdSum,
    currency = "$"
  ) {
    const width = chart.width,
      height = chart.height,
      ctx = chart.ctx;
    ctx.restore();
    ctx.font = "bold 24px sans-serif";
    ctx.textBaseline = "center";
    ctx.fillStyle = "#2E2E2E";
    const text = `${percentFact}%`,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;
    ctx.fillText(text, textX, textY);
    ctx.save();
    ctx.font = "14px sans-serif";
    ctx.fillStyle = "#707070";
    const text2 = `${currency}${thresholdFactSum} / ${currency}${thresholdSum}`,
      textX2 = Math.round((width - ctx.measureText(text2).width) / 2),
      textY2 = height / 1.55;
    ctx.fillText(text2, textX2, textY2);
    ctx.save();
  };

  renderSubscriptionDialog = () => {
    const { t } = this.props;
    const { actionDialogs } = this.state;

    return (
      <Modal
        title={t("dash.warning")}
        visible={actionDialogs.subscription.open}
        onClose={() => {
          this.handleActionDialogsClose("subscription");
        }}
        content={
          <p>
            {t("dash.overPlan")} (
            {
              <Link className='Polaris-Link' to='/subscription'>
                {t("dash.selectPlan")}
              </Link>
            }
            ){t("dash.dot")}
            <br />
            {t("dash.canCont")}
            {t("dash.dot")}
          </p>
        }
      />
    );
  };

  sortRowsByStatus = (rows) => {
    const statusOrder = { red: 1, yellow: 2 };
    return rows.slice().sort((a, b) => {
      const statusA = a.status.toLowerCase();
      const statusB = b.status.toLowerCase();
      return (
        statusOrder[statusA] - statusOrder[statusB] ||
        statusA.localeCompare(statusB)
      );
    });
  };

  renderDueDates = () => {
    const { t, dueDates, fetchingDueDates, user } = this.props;
    const IS_TAX_AGENT = isTaxAgentSelector(user);
    const rows = this.sortRowsByStatus(dueDates.items ?? []);

    const columns = [
      {
        property: "country",
      },
      {
        property: "period_type",
        cell: {
          formatters: [
            (value, { rowData }) => {
              if (rowData.type_of_report.includes("Sales Tax")) {
                return this.getPeriodTypeForSalesTax(rowData.period_type);
              } else if (rowData.type_of_report.includes("EPR")) {
                return this.getPeriodTypeForEPR(rowData.period_type);
              }

              return this.getPeriodTypeForVat(rowData.period_type);
            },
          ],
        },
      },
      {
        cell: {
          formatters: [
            (value, { rowData }) =>
              `${rowData.period} ${rowData.period_type} ${rowData.period_year}`,
          ],
        },
      },
      {
        property: "exp_date",
      },
      {
        property: "",
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                (rowData.status === "red" || rowData.status === "yellow") && (
                  <AlertIcon
                    stroke={rowData.status === "red" ? "#F97066" : "#B8821B"}
                  />
                )
              );
            },
          ],
        },
      },
      {
        property: "",
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                <Inline align='end'>
                  {!IS_TAX_AGENT ? (
                    <Button
                      primary
                      onClick={() => {
                        if (rowData.type_of_report.includes("VAT return")) {
                          this.props.navigate("/vat-returns");
                        } else {
                          this.props.navigate("/tax-returns");
                        }
                      }}
                    >
                      {t("dash.fillVR")}
                    </Button>
                  ) : (
                    <Text>{rowData.company_name}</Text>
                  )}
                </Inline>
              );
            },
          ],
        },
      },
    ];

    const sortedRows = formatRow(rows, columns);

    return (
      <Card
        title={t("dash.title.yourDD")}
        actions={[
          {
            content: t("dash.viewAll"),
            onClick: () => this.props.navigate("/dashboard/due-dates"),
          },
        ]}
        sectioned={fetchingDueDates || _.isEmpty(rows)}
      >
        {fetchingDueDates ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {!_.isEmpty(rows) && !fetchingDueDates && (
              <>
                <br />
                <div className='border-top'></div>
                <HighlightableDataTable
                  headings={[]}
                  rows={sortedRows}
                  rawItems={rows}
                  rowClassNameCallback={(row, rowData) => ({
                    "bg-red": rowData.status === "red",
                    "bg-yellow": rowData.status === "yellow",
                  })}
                  styles={{
                    td: {
                      padding: "0.3rem 0.85rem",
                    },
                  }}
                />
              </>
            )}

            {_.isEmpty(rows) && !fetchingDueDates && (
              <Card.Section>
                <NoDataMessage
                  title={"Nothing to show here yet"}
                  description={"Add store to see data"}
                  buttonText={"Add store"}
                  Image={CalendarImg}
                  style={{ height: "auto" }}
                  onClick={() => this.props.navigate("/stores/add")}
                />
              </Card.Section>
            )}
          </React.Fragment>
        )}
      </Card>
    );
  };

  renderVatReturns = () => {
    const { t, declarations, fetchingDeclarations } = this.props;
    const rows = declarations ? declarations.items : [];

    return (
      <Card
        title={t("dash.title.vatRet")}
        actions={[
          {
            content: t("dash.viewAll"),
            onClick: () => this.props.navigate("/vat-returns"),
          },
        ]}
        sectioned={fetchingDeclarations || _.isEmpty(rows)}
      >
        {fetchingDeclarations ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {!_.isEmpty(rows) > 0 && !fetchingDeclarations && (
              <>
                <br />
                <div className='border-top'>
                  <DataTable
                    columnContentTypes={[
                      "text",
                      "numeric",
                      "numeric",
                      "numeric",
                    ]}
                    headings={[]}
                    rows={[...rows].map((rowData) => [
                      rowData.customer_country.name,
                      this.getPeriodTypeForVat(rowData.period_type),
                      `${rowData.period} ${rowData.period_type} ${rowData.period_year}`,
                      this.getStatusForVat(rowData.status),
                    ])}
                  />
                </div>
              </>
            )}
            {_.isEmpty(rows) && !fetchingDeclarations && (
              <Card.Section>
                <NoDataMessage
                  title={"Nothing to show here yet"}
                  description={"Add VAT returns to see data"}
                  buttonText={
                    this.props.user.vat_returns_show_in_menu
                      ? "Add VAT returns"
                      : null
                  }
                  Image={ReminderImg}
                  style={{ height: "auto" }}
                  onClick={() =>
                    this.props.navigate("/vat-returns/create-return")
                  }
                />
              </Card.Section>
            )}
          </React.Fragment>
        )}
        </Card>
    );
  };

  renderSalesTaxReturns = () => {
    const { t, declarationsSalesTax, fetchDeclarationsSalesTax, fetchingSalesTax } = this.props;
    const rows = declarationsSalesTax ? declarationsSalesTax.items : [];
    console.log('declarationsSalesTax', declarationsSalesTax)
    return (
      <Card
        title={t("dash.title.salesTaxRet")}
        actions={[
          {
            content: t("dash.viewAll"),
            onClick: () => this.props.navigate("/tax-returns"),
          },
        ]}
        sectioned={fetchDeclarationsSalesTax || _.isEmpty(rows)}
      >
        {fetchingSalesTax ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {!_.isEmpty(rows) > 0 && !fetchingSalesTax && (
              <>
                <br />
                <div className='border-top'>
                  <DataTable
                    columnContentTypes={[
                      "text",
                      "numeric",
                      "numeric",
                      "numeric",
                    ]}
                    headings={[]}
                    rows={[...rows].map((rowData) => [
                      rowData.state.name,
                      this.getPeriodTypeForVat(rowData.period_type),
                      `${rowData.period} ${rowData.period_type} ${rowData.period_year}`,
                      this.getStatusForVat(rowData.status),
                    ])}
                  />
                </div>
              </>
            )}
            {_.isEmpty(rows) && !fetchDeclarationsSalesTax && (
              <Card.Section>
                <NoDataMessage
                  title={"Nothing to show here yet"}
                  description={"Add VAT returns to see data"}
                  buttonText={
                    this.props.user.vat_returns_show_in_menu
                      ? "Add TAX returns"
                      : null
                  }
                  Image={ReminderImg}
                  style={{ height: "auto" }}
                  onClick={() =>
                    this.props.navigate("/tax-returns/create-return")
                  }
                />
              </Card.Section>
            )}
          </React.Fragment>
        )}
        </Card>
    );
  };


  renderEPRReturns = () => {
    const { t, declarationsEpr, fetchEprDeclarations } = this.props;
    const rows = declarationsEpr ? declarationsEpr.items : [];
    console.log( declarationsEpr)
    return (
      <Card
        title={t("dash.title.eprRet")}
        actions={[
          {
            content: t("dash.viewAll"),
            onClick: () => this.props.navigate("/epr-reporting"),
          },
        ]}
        sectioned={fetchEprDeclarations || _.isEmpty(rows)}
      >
        {declarationsEpr.items <= 0 ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {!_.isEmpty(rows) > 0 && (
              <>
                <br />
                <div className='border-top'>
                  <DataTable
                    columnContentTypes={[
                      "text",
                      "numeric",
                      "numeric",
                      "numeric",
                    ]}
                    headings={[]}
                    rows={[...rows].map((rowData) => [
                      rowData.name,
                      this.getPeriodTypeForVat(rowData.period_type),
                      rowData.country_name,
                      // `From ${rowData.period_start} to ${rowData.period_end}`,
                      `${rowData.period} ${rowData.period_type} ${rowData.period_year}`,
                      this.getStatusForVat(rowData.status),
                    ])}
                  />
                </div>
              </>
            )}
            {_.isEmpty(rows) && !fetchEprDeclarations && (
              <Card.Section>
                <NoDataMessage
                  title={"Nothing to show here yet"}
                  description={"Add VAT returns to see data"}
                  buttonText={
                    this.props.user.vat_returns_show_in_menu
                      ? "Add VAT returns"
                      : null
                  }
                  Image={ReminderImg}
                  style={{ height: "auto" }}
                  onClick={() =>
                    this.props.navigate("/epr-reporting/create-return")
                  }
                />
              </Card.Section>
            )}
          </React.Fragment>
        )}
        </Card>
    );
  };

  renderNews = () => {
    const { t, fetchingNews } = this.props;
    const { news } = this.props || [];

    return (
      <Card
        title={t("dash.title.news")}
        sectioned={fetchingNews}
        actions={[
          {
            content: t("dash.visitBl"),
            onClick: () => this.props.navigate("/dashboard/news"),
          },
        ]}
      >
        {fetchingNews && <Spinner />}
        {!fetchingNews && !_.isEmpty(news) && (
          <>
            {news.map((newsItem) => (
              <Card.Section key={newsItem.id}>
                <Stack wrap={false}>
                  <Stack.Item>
                    {newsItem?.imageSrc && (
                      <img
                        alt='news'
                        width='180px'
                        height='100%'
                        style={{
                          borderRadius: "8px",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                        src={newsItem?.imageSrc || ""}
                      />
                    )}
                  </Stack.Item>
                  <Stack.Item vertical fill='true'>
                    <AlphaStack spacing='2'>
                      <TextStyle variation='subdued'>
                        {formatDate(newsItem.date)}
                      </TextStyle>
                      <Heading>{newsItem.title}</Heading>
                      <TextStyle>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: newsItem.description,
                          }}
                        />
                      </TextStyle>
                      <Link
                        className='Polaris-Link'
                        to={`/dashboard/news/${newsItem.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        Read more
                      </Link>
                    </AlphaStack>
                  </Stack.Item>
                </Stack>
              </Card.Section>
            ))}
          </>
        )}
        {!fetchingNews && _.isEmpty(news) && (
          <Card.Section>
            <NoDataMessage
              title={"No articles yet"}
              Image={FinanceImg}
              style={{ height: "auto" }}
            />
          </Card.Section>
        )}
      </Card>
    );
  };

  renderUserGuide = () => {
    return (
      <Card title={"User Guide"}>
        <Card.Section>
          <Banner title={"in development"} status='critical' />
        </Card.Section>
      </Card>
    );
  };

  renderThresholdUSChart = () => {
    const { activeStateInd } = this.state;
    const thresholdUS = this.props.thresholdUS
      ? this.props.thresholdUS.items
      : [];
    const labelsForData = ["", ...thresholdUS.map((item) => item.state)];
    const dataForSeries = [100, ...thresholdUS.map((item) => item.percent)];
    const data = {
      labels: labelsForData,
      series: [dataForSeries],
    };
    const total = 100;
    const thresholdFactSum = thresholdUS[activeStateInd].fact_sum;
    const thresholdSum = Math.round(thresholdUS[activeStateInd].threshold_sum);
    const percentFact = thresholdUS[activeStateInd].percent;

    const options = {
      width: "100%",
      height: thresholdUS.length * 25,
      horizontalBars: true,
      reverseData: true,
      chartPadding: {
        bottom: 25,
        right: 35,
      },
      axisX: {
        position: "start",
        labelInterpolationFnc: (value) => `${value.toFixed()}%`,
      },
      axisY: {
        offset: 100,
      },
    };
    return (
      <Card.Section>
        <Stack distribution='center'>
          <Pagination
            label={
              <TextStyle variation='strong'>
                {thresholdUS[activeStateInd].state}
              </TextStyle>
            }
            hasPrevious
            onPrevious={() =>
              this.setState({
                activeStateInd:
                  activeStateInd > 0
                    ? activeStateInd - 1
                    : thresholdUS.length - 1,
              })
            }
            hasNext
            onNext={() =>
              this.setState({
                activeStateInd:
                  activeStateInd < thresholdUS.length - 1
                    ? activeStateInd + 1
                    : 0,
              })
            }
          />
        </Stack>
        <br />
        <div
          key={thresholdUS[activeStateInd].state_code}
          className='dash-chart-container'
        >
          <Doughnut
            options={{
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      return `${context.formattedValue}%`;
                    },
                  },
                },
              },
            }}
            data={{
              datasets: [
                {
                  data: [
                    thresholdUS[activeStateInd].percent,
                    total - (thresholdUS[activeStateInd].percent || 0),
                  ],
                  radius: "100%",
                  cutout: 70,
                  backgroundColor: ["#59C7C5", "#DAF1F2"],
                  borderColor: ["#59C7C5", "#DAF1F2"],
                  hoverBackgroundColor: ["#59C7C5", "#DAF1F2"],
                },
              ],
            }}
            plugins={[
              {
                beforeDraw: (chart) =>
                  this.beforeDraw(
                    chart,
                    percentFact,
                    thresholdFactSum,
                    thresholdSum,
                    "$"
                  ),
              },
            ]}
          />
        </div>
      </Card.Section>
    );
  };

  renderThresholdUS = () => {
    const thresholdUS = this.props.thresholdUS
      ? this.props.thresholdUS.items
      : [];
    const { t, fetchingThresholdUS } = this.props;
    const message = thresholdUS ? thresholdUS.message : null;
    const today = new Date();

    return (
      <React.Fragment>
        <Card
          title={"Threshold USA"}
          actions={[
            {
              content: t("dash.viewAll"),
              onClick: () => this.props.navigate("/dashboard/threshold"),
            },
          ]}
          sectioned={_.isEmpty(thresholdUS)}
        >
          {fetchingThresholdUS ? (
            <Card.Section>
              <Spinner />
            </Card.Section>
          ) : (
            <React.Fragment>
              {_.isEmpty(thresholdUS) && <TextStyle>No data</TextStyle>}
              {!_.isEmpty(thresholdUS) && this.renderThresholdUSChart()}
              {message && _.isEmpty(thresholdUS) && (
                <TextStyle>{message}</TextStyle>
              )}
            </React.Fragment>
          )}
        </Card>
        <br />
      </React.Fragment>
    );
  };

  renderThresholdEU = () => {
    const { t, thresholdEU, fetchingThresholdEU } = this.props;
    const total = 100;
    return (
      <Card
        title={"Threshold EU"}
        actions={[
          {
            content: t("dash.viewAll"),
            onClick: () => this.props.navigate("/dashboard/threshold"),
          },
        ]}
      >
        {fetchingThresholdEU ? (
          <Card.Section>
            <Spinner />
          </Card.Section>
        ) : (
          <React.Fragment>
            <Card.Section>
              <div className='dash-chart-container'>
                <Doughnut
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            return `${context.formattedValue}%`;
                          },
                        },
                      },
                    },
                  }}
                  data={{
                    datasets: [
                      {
                        data: [
                          thresholdEU.percent,
                          total - (thresholdEU.percent || 0),
                        ],
                        radius: "100%",
                        cutout: 70,
                        backgroundColor: ["#59C7C5", "#DAF1F2"],
                        borderColor: ["#59C7C5", "#DAF1F2"],
                        hoverBackgroundColor: ["#59C7C5", "#DAF1F2"],
                      },
                    ],
                  }}
                  plugins={[
                    {
                      beforeDraw: (chart) =>
                        this.beforeDraw(
                          chart,
                          thresholdEU.percent,
                          thresholdEU.sum,
                          Math.round(thresholdEU.threshold_sum),
                          "€"
                        ),
                    },
                  ]}
                />
              </div>
            </Card.Section>
          </React.Fragment>
        )}
      </Card>
    );
  };

  renderDeemedVat = () => {
    const { deemedVat, fetchingDeemedVat } = this.props;

    const data = {
      labels: deemedVat.map((item) => item.month_name),
      datasets: [
        {
          label: "Deemed Import",
          data: deemedVat.map((item) => item.deemed_import),
          backgroundColor: chartSeriesColors[0],
        },
        {
          label: "Deemed EUR",
          data: deemedVat.map((item) => item.deemed_eu),
          backgroundColor: chartSeriesColors[1],
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          align: "center",
        },
      },
      scales: {
        x: {
          position: "bottom",
        },
        y: {
          beginAtZero: true,
          offset: true,
          grace: "10%",
        },
      },
    };

    return (
      <Card sectioned title={"Deemed VAT in thousand EUR"}>
        {fetchingDeemedVat ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {!_.isEmpty(deemedVat) ? (
              <div style={{ width: "100%", height: "250px" }}>
                <Bar data={data} options={options} />
              </div>
            ) : (
              <p style={{ padding: "2rem" }}>No data</p>
            )}
          </React.Fragment>
        )}
      </Card>
    );
  };

  renderTransactionsByCountryOmp = () => {
    const { transByCountry, fetchingTransByCountry } = this.props;
    const rows = transByCountry ? [...transByCountry.items].reverse() : [];

    const data = {
      labels: rows.map((item) => item.country),
      datasets: [
        {
          data: rows.map((item) => item.amount),
          backgroundColor: chartSeriesColors[0],
        },
      ],
    };

    const options = {
      indexAxis: "y",
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          position: "bottom",
          ticks: {
            callback: (value) => `${value}%`,
          },
        },
        y: {
          reverse: true,
        },
      },
    };

    return (
      <Card
        title={"Transactions by country"}
        sectioned={fetchingTransByCountry}
      >
        {fetchingTransByCountry ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {!_.isEmpty(rows) ? (
              <div
                style={{
                  width: "100%",
                  height: `${100 + rows.length * 30}px`,
                  padding: "1rem",
                }}
              >
                <Bar data={data} options={options} />
              </div>
            ) : (
              <p style={{ padding: "2rem" }}>No data</p>
            )}
          </React.Fragment>
        )}
      </Card>
    );
  };

  renderPaymentInfoBlock = (type, sub) => {
    const { t, currentPlan } = this.props;

    const getDataForType = () => {
      switch (type) {
        case "vat":
          return {
            sum: currentPlan.sum,
            nextBillDate: currentPlan.your_next_billing_date,
            title: t("dash.vatSub"),
          };
        case "sales tax":
          return {
            sum: currentPlan.sum_sales_tax,
            nextBillDate: currentPlan.your_next_billing_date_sales_tax,
            title: t("dash.taxSub"),
          };
        case "omp":
          return {
            sum: currentPlan.sum_omp,
            nextBillDate: currentPlan.your_next_billing_date_omp,
            title: "OMP subscription",
          };

        default:
          return {};
      }
    };

    const { title, nextBillDate, sum } = getDataForType(type);

    return (
      <Inline align='space-between'>
        <TextStyle variation='strong'>{title}</TextStyle>

        <AlphaStack spacing='2' align='end'>
          <Inline alignY='center' align='end'>
            <Badge>
              {sub.tariff_plan.name} {t("dash.plan")}
            </Badge>{" "}
            -{" "}
            <Text color='subdued'>
              {formatMoney(sum)}
              {getCurrencySymbol(currentPlan.currency_code)}/ {t("dash.month")}
            </Text>
          </Inline>
          <Inline alignY='center' align='end'>
            <Text>{t("dash.nextBilling")} - </Text>
            <Text color='subdued'>{formatDate(nextBillDate)}</Text>
          </Inline>
        </AlphaStack>
      </Inline>
    );
  };

  renderEprPaymentInfo(sub) {
    const { t } = this.props;
    const { countries, types, name, your_next_billing_date } = sub;
    return (
      <Inline align='space-between'>
        <TextStyle variation='strong'>EPR subscription</TextStyle>
        <AlphaStack spacing='2' align='end'>
          <Inline>
            <Badge>{name}</Badge>{" "}
            <Text color='subdued'>
              {countries} {countries > 1 ? "countries" : "country"} {types}{" "}
              {types > 1 ? "types" : "type"}
            </Text>
          </Inline>
          <Inline alignY='center' align='end'>
            <Text>{t("dash.nextBilling")} - </Text>
            <Text color='subdued'>{formatDate(your_next_billing_date)}</Text>
          </Inline>
        </AlphaStack>
      </Inline>
    );
  }

  renderTaxAgentClients = () => {
    const { paymentInfo } = this.props;
    return (
      <Scrollable shadow className='Scrollable'>
        <br />
        {!_.isEmpty(paymentInfo) ? (
          <ResourceList
            items={paymentInfo}
            renderItem={(item, index) => (
              <ResourceItem
                key={`${index}-row`}
                id={index}
                accessibilityLabel={item.company}
              >
                <h3>
                  <TextStyle variation='strong'>{item.company}</TextStyle>
                </h3>
                <div>Subscription Plan: {item.subs}</div>
                {item.next_billing_date && (
                  <div>{formatDate(item.next_billing_date)}</div>
                )}
              </ResourceItem>
            )}
          />
        ) : (
          <p style={{ padding: "2rem " }}>You don't have any client.</p>
        )}
      </Scrollable>
    );
  };

  renderRemittedSalesTax = () => {
    return (
      <Card title={"Remitted sales tax by states"} sectioned>
        There is not a single sale
      </Card>
    );
  };

  render() {
    const {
      user,
      currentPlan,
      lastUploaded,
      fetchingPaymentInfo,
      thresholdUS,
      thresholdEU,
      bonusProgram,
      fetchingBonus,
      editingBalance,
      t,
      credits,
    } = this.props;

    const { balance, editing, editingError } = this.state;

    const IS_TAX_AGENT = isTaxAgentSelector(user);

    return _.isEmpty(user) ? (
      <div style={{ padding: "1rem" }}>
        <Spinner />
      </div>
    ) : (
      <Page
        fullWidth
        title={
          <Text variant='heading3xl' as='span'>
            {t("dash.hello")}, {user.first_name || ""}
          </Text>
        }
        subtitle={
          user.company &&
          user.company.id !== user.tax_agent_company_id &&
          !(user.is_manager && user.company.is_tax_agent) && (
            <Text variant='bodyLg' as='span' color='subdued'>
              {t("dash.how")}
            </Text>
          )
        }
        primaryAction={
          <Inline spacing='6' alignY='center'>
            {lastUploaded && lastUploaded[0] && (
              <Text color='subdued'>
                <div style={{ textAlign: "right" }}>
                  {t("dash.lastTransUp")} <br />
                  {lastUploaded[0] &&
                    `${formatDate(
                      lastUploaded[0].transactions_date_update
                    )} ${formatTime(lastUploaded[0].transactions_date_update)}`}
                </div>
              </Text>
            )}

            {!IS_TAX_AGENT &&
              user.company.id !== user.tax_agent_company_id &&
              !user.is_omp && (
                <Button
                  icon={AddIcon}
                  outline
                  size='large'
                  onClick={() => {
                    if (
                      (!user.is_manager &&
                        !user.is_omp &&
                        currentPlan &&
                        !currentPlan.is_active &&
                        !currentPlan.is_active_25 &&
                        !currentPlan.is_active_sales_tax) ||
                      (!user.is_manager && !user.is_omp && !currentPlan)
                    ) {
                      this.handleActionDialogsOpen("subscription");
                    } else {
                      this.props.navigate("/transactions/import");
                    }
                  }}
                >
                  <span style={{ marginLeft: "12px" }}>
                    {" "}
                    {t("dash.addTrans")}
                  </span>
                </Button>
              )}
          </Inline>
        }
      >
        <PageHelmet title='Dashboard' />

        <Layout>
          <Layout.Section twoThird>
            <AlphaStack
              spacing='2'
              fullWidth
            >
              {user.is_omp && this.renderDeemedVat()}

              {!user.company.is_tax_agent && this.renderDueDates()}

              {!(user.is_manager && user.company.is_tax_agent) &&
                user.company.id !== user.tax_agent_company_id &&
                !user.is_omp && currentPlan?.subscriptions && this.renderVatReturns()}

              {/*{console.log("currentPlan", currentPlan)}*/}
              <div className='sales-tax-returns'>
                {!(user.is_manager && user.company.is_tax_agent) &&
                  user.company.id !== user.tax_agent_company_id &&
                  !user.is_omp && currentPlan?.subscriptions_sales_tax && this.renderSalesTaxReturns()}
              </div >
              <div className='epr-returns'>
                {!(user.is_manager && user.company.is_tax_agent) &&
                  user.company.id !== user.tax_agent_company_id &&
                  !user.is_omp && currentPlan?.subscriptions_epr && this.renderEPRReturns()}
              </div>

                {user.is_omp && this.renderRemittedSalesTax()}

                {this.renderNews()}
            </AlphaStack >
          </Layout.Section >
          <Layout.Section oneThird>
            {(balance || user.is_manager || !_.isEmpty(credits)) && (
              <Card>
                {(balance || user.is_manager) && (
                  <>
                    <Card.Section>
                      {editingError && (
                        <React.Fragment>
                          <Banner title={editingError} status='critical' />
                          <br />
                        </React.Fragment>
                      )}
                      {editing ? (
                        <div style={{ width: "50%" }}>
                          <TextField
                            type='number'
                            value={balance}
                            onChange={(v) => this.setState({ balance: v })}
                          />
                        </div>
                      ) : (
                        <Inline alignY='center' align='space-between'>
                          <Inline alignY='center' spacing='4'>
                            <img src={CardImg} alt='card' />
                            <TextContainer>
                              <Heading>
                                {t("dash.balance")} ({t("dash.deposit")})
                              </Heading>
                              <span>
                                {balance} {" \u{20AC} "}
                              </span>
                            </TextContainer>
                            {user.is_manager && (
                              <Button
                                plain
                                onClick={() => this.setState({ editing: true })}
                              >
                                <Icon source={EditMajor} color='base' />
                              </Button>
                            )}
                          </Inline>
                          <Button disabled primary>
                            Add deposit
                          </Button>
                        </Inline>
                      )}
                    </Card.Section>
                    {editing && (
                      <Card.Section>
                        <ButtonGroup>
                          <Button
                            primary
                            loading={editingBalance}
                            onClick={this.doEditBalance}
                          >
                            Save
                          </Button>
                          <Button
                            disabled={editingBalance}
                            onClick={() => this.setState({ editing: false })}
                          >
                            Cancel
                          </Button>
                        </ButtonGroup>
                      </Card.Section>
                    )}
                  </>
                )}
                {!_.isEmpty(credits) && (
                  <Card.Section
                    title={<Text variant='headingMd'>{"Credits"}</Text>}
                  >
                    <AlphaStack spacing='4' fullWidth>
                      {credits.map((credit, index) => (
                        <Inline align='space-between' key={credit.count + index}>
                          <Text variant='bodyMd'>{credit.name}</Text>
                          <Text color='subdued'>
                            {credit.count}
                            {credit.count > 1 ? " credits" : " credit"}
                          </Text>
                        </Inline>
                      ))}
                    </AlphaStack>
                  </Card.Section>
                )}
              </Card>
            )}

            {IS_TAX_AGENT && (
              <Card>
                {fetchingBonus ? (
                  <Card.Section>
                    <Spinner />
                  </Card.Section>
                ) : (
                  <Card.Section>
                    <TextContainer>
                      <Text>
                        Your partner level is{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {bonusProgram.level}
                        </span>
                      </Text>
                      <Text>
                        Give you{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {bonusProgram.discount}% discount
                        </span>{" "}
                        to all services
                      </Text>
                      <p>
                        You need{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {bonusProgram.need_client}
                        </span>{" "}
                        clients or referrals for achieve next level
                      </p>
                    </TextContainer>
                  </Card.Section>
                )}
              </Card>
            )}

            {!user.is_tax_agent &&
              !(user.is_manager && user.company.is_tax_agent) && (
                <Card title={"Current subscription plan"} sectioned>
                  {fetchingPaymentInfo ? (
                    <Spinner />
                  ) : (
                    <React.Fragment>
                      {!currentPlan && (
                        <TextStyle>
                          {t("dash.overPlan")} (
                          <Link className='Polaris-Link' to='/subscription'>
                            {t("dash.selectPlan")}
                          </Link>
                          ){t("dash.dot")}
                          <br />
                          {t("dash.canCont")}
                          {t("dash.dot")}
                        </TextStyle>
                      )}

                      {currentPlan &&
                        currentPlan.subscriptions &&
                        this.renderPaymentInfoBlock(
                          "vat",
                          currentPlan.subscriptions
                        )}

                      {currentPlan &&
                        currentPlan.subscriptions &&
                        currentPlan.subscriptions_sales_tax && <br />}

                      {currentPlan &&
                        currentPlan.subscriptions_sales_tax &&
                        this.renderPaymentInfoBlock(
                          "sales tax",
                          currentPlan.subscriptions_sales_tax
                        )}
                      {currentPlan && currentPlan.subscriptions_epr && <br />}
                      {currentPlan &&
                        currentPlan.subscriptions_epr &&
                        this.renderEprPaymentInfo(
                          currentPlan.subscriptions_epr
                        )}
                      {currentPlan &&
                        currentPlan.subscriptions_omp &&
                        this.renderPaymentInfoBlock(
                          "omp",
                          currentPlan.subscriptions_omp
                        )}
                    </React.Fragment>
                  )}
                </Card>
              )}
            {!user.is_tax_agent && <br />}
            <UserGuide />
            {!IS_TAX_AGENT && (
              <>
                {thresholdUS && this.renderThresholdUS()}
                {thresholdEU && this.renderThresholdEU()}
                {thresholdEU && <br />}
              </>
            )}

            {IS_TAX_AGENT && (
              <Card title={t("dash.title.payInfo")}>
                {fetchingPaymentInfo ? (
                  <Card.Section>
                    <Spinner />
                  </Card.Section>
                ) : (
                  this.renderTaxAgentClients()
                )}
              </Card>
            )}

            {user.is_omp && this.renderTransactionsByCountryOmp()}
          </Layout.Section>
        </Layout>

        {this.renderSubscriptionDialog()}
        <br />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  userGuide: state.user.userGuide,
  declarations: state.declarations.declarations,
  fetchingDeclarations: state.declarations.fetching,
  declarationsSalesTax: state.declarations.declarationsSalesTax,
  fetchDeclarationsSalesTax: state.declarations.fetching,
  fetchingSalesTax: state.declarations.fetchingSalesTax,
  declarationsEpr: state.declarations.declarationsEpr,
  fetchEprDeclarations: state.declarations.fetching,
  currentPlan: state.subscription.currentPlan,
  news: state.dashboard.news,
  fetchingNews: state.dashboard.fetchingNews,
  dueDates: state.dueDates.dueDates,
  fetchingDueDates: state.dueDates.fetching,
  revenue: state.dashboard.revenue,
  paymentInfo: state.profile.paymentInfo,
  fetchingPaymentInfo: state.profile.fetchingPaymentInfo,
  thresholdEU: state.thresholds.thresholdEU,
  fetchingThresholdEU: state.thresholds.fetchingThresholdEU,
  thresholdUS: state.thresholds.thresholdUS,
  fetchingThresholdUS: state.thresholds.fetchingThresholdEU,
  lastUploaded: state.dashboard.lastUploaded,
  defaultLanguage: state.user.defaultLanguage,
  deemedVat: state.dashboard.deemedVat,
  fetchingDeemedVat: state.dashboard.fetchingDeemedVat,
  transByCountry: state.dashboard.transByCountry,
  fetchingTransByCountry: state.dashboard.fetchingTransByCountry,
  bonusProgram: state.tax.bonusProgram,
  fetchingBonus: state.tax.fetchingBonus,
  balance: state.dashboard.balance,
  fetchingBalance: state.dashboard.fetchingBalance,
  editingBalance: state.dashboard.editingBalance,
  fetchingCredits: state.dashboard.fetchingCredits,
  credits: state.dashboard.credits,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchCompanyDefaultLanguage: () => dispatch(fetchCompanyDefaultLanguage()),
    fetchLastUploadedTansation: () => dispatch(fetchLastUploadedTansation()),
    fetchNews: (params) => dispatch(fetchNews(params)),
    fetchDueDates: (params) => dispatch(fetchDueDates(params)),
    fetchDeclarations: (params) => dispatch(fetchDeclarations(params)),
    fetchDeclarationsSalesTax: (params) => dispatch(fetchDeclarationsSalesTax(params)),
    fetchEprDeclarations: (params) => dispatch(fetchEprDeclarations(params)),
    fetchThresholdEU: (params) => dispatch(fetchThresholdEU(params)),
    fetchThresholdUS: (params) => dispatch(fetchThresholdUS(params)),
    fetchPaymentInfo: () => dispatch(fetchPaymentInfo()),
    fetchDeemedVat: () => dispatch(fetchDeemedVat()),
    fetchTransByCountryOmp: () => dispatch(fetchTransByCountryOmp()),
    fetchBonusProgram: () => dispatch(fetchBonusProgram()),
    fetchBalance: () => dispatch(fetchBalance()),
    editBalance: (params) => dispatch(editBalance(params)),
    fetchCredits: () => dispatch(fetchCredits()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard))
);
